/* IMPORTS */
/* ############################### */
@font-face {
  font-family: 'Futura-LT';
  src: url('../../public/fonts/Futura-Light-webfont.eot'); /* IE9 Compat Modes */
  src: url('../../public/fonts/Futura-Light-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/fonts/Futura-Light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/fonts/Futura-Light-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../public/fonts/Futura-Light-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../public/fonts/Futura-Light-webfont.svg#Futura-LT') format('svg'); /* Legacy iOS */
}
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* BASE CSS */
/* ############################### */
/* OVERRIDES */
html, body {
  font-size: 62.5%;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.2;
  color: rgba(0,0,0,0.87);
  margin: 0;
}
#root {
  overflow: hidden;
  font-size: 2.25rem;
}
*:focus {
  outline: none;
}
ul {
  margin-left: 2rem;
}
ul, li {
  padding: 0.25rem;
}
.material-icons {
  font-size: initial !important;
  line-height: 1.1 !important;
}

/* LINKS */
a:link,
a:visited,
a:active {
  color: #4183c4;
  text-decoration: none;
}
a:hover {
  color: #1e70bf;
  text-decoration: none;
}

/* ALIGNMENTS */
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.centered {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.right-aligned {
  display: block;
  margin-right: 0;
}
.push__up {
  margin-bottom: -6.5em;
  position: relative;
  top: -6.5em;
  width: 100vw;
  z-index: 10;
}
.navpoint {
  margin-top: -13vh;
  position: absolute;
}

/* CUSTOM CLASSES */
.alert {
  margin-right: 0.5rem;
  margin-top: 1rem;
}
.bullet .material-icons,
.bullet svg {
  font-size: 7rem !important;
}
.bullet img {
  max-height: 4.5vh !important;
  width: 100%;
}

/* CUSTOM COMPONENTS */
.Section.FourZeroFour img {
  width: 100%;
}
.Map {
  min-height: 50vh;
  margin: 0 -5.6rem 0 -100%;
  display: flex;
}
.OfficeSuite img {
  width: 60% !important;
}
.Main .content .MuiPaper-root img.centered {
  margin-top: 0 !important;
}

/* NAVIGATION */
/* NORMAL NAVIGATION BAR */
.NavBar {
  background: none !important;
  top: 8vh !important;
  box-shadow: none !important;
}
.NavFloat {
  background-color: rgba(30,64,92,0.9) !important;
  padding: 2rem;
}
.MobileLogo,
.NavMobile,
.NavMobile .mobile,
.NavBar .mobile,
.NavFloat .mobile {
  display: none !important;
}
.NavBar .logo, .NavFloat .logo {
  width: auto;
  height: 5.5vh;
}
.NavBar a:active,
.NavBar a:visited,
.NavBar a:link,
.NavFloat a:active,
.NavFloat a:visited,
.NavFloat a:link,
.drawer a:active,
.drawer a:visited,
.drawer a:link {
  color: white;
}
.NavBar .Menu a:active,
.NavBar .Menu a:visited,
.NavBar .Menu a:link,
.NavFloat .Menu a:active,
.NavFloat .Menu a:visited,
.NavFloat .Menu a:link {
  color: rgba(0,0,0,87);
}
.NavBar .MuiGrid2-root, .NavFloat .MuiGrid2-root {
  align-self: center;
}
.NavBar	.MuiButton-root, .NavFloat .MuiButton-root {
  background-color: rgba(0,0,0,0.15);
}
.NavBar .dropdown .MuiMenuItem-root, .NavFloat .dropdown .MuiMenuItem-root {
  padding: 2rem 2.5rem !important;
}
.NavBar .MuiButton-root:hover, .NavFloat .MuiButton-root:hover {
  background-color: rgba(0,0,0,0.25);
}
.NavBar .nav_9, .NavFloat .nav_9 {
  color: white;
  background: rgba(0,168,228,0.50) !important;
}
.NavBar .nav_9:hover, .NavFloat .nav_9:hover {
  background: rgba(0, 168, 228, 1) !important;
}

/* TOP ALERTS */
.Alert {
  background: #333;
  color: white;
  padding: 1em;
  box-shadow: inset 0 -1px 2px rgba(0,0,0,0.5);
}
.Alert a:link,
.Alert a:active,
.Alert a:visited {
  color: white;
  text-decoration: none;
}
.Alert a:hover {
  color: #CCC;
}
.Alert .mobile-logo {
  display: none;
}
.Alert .right {
  font-size: 1.5rem;
  text-align: right;
}
.Alert .left {
  font-size: 1.5rem;
  text-align: left;
}
.Alert .material-icons {
  font-size: 1.5rem;
  line-height: 1.2;
  padding: 0 0.5rem 0 0;
}

/* BANNER */
.Banner {
  overflow: hidden;
  color: #FFFFFF;
  position: relative;
  z-index: 1;
}
.Banner.video {
  height: calc(100vw - 45vh);
  max-height: 90vh;
  min-height: 45vh;
}
.Banner.picture {
  background-position: top 60% right 55%;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-attachment: local !important;
}
.Banner.plain {
  background: rgba(30,64,92,.9);
  height: 16vh;
}
.Banner .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  vertical-align: middle;
  display: flex;
}
.Banner .content {
  margin: auto;
  top: -3.7vh;
  position: relative;
}

.Banner .content img {
  width: auto;
  height: 5vh;
}

.Banner.video .overlay {
  background-image: url('../../public/images/overlay.png');
  background-color: rgba(0,0,0,0.25);
}
.Banner video {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  object-fit: cover;
}
.Banner .BannerTitle {
  padding: 5.6rem 0;
  background-color: rgba(30,64,92,.9);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 7%;
  min-height: 3vh;
  max-height: 8vh;
  display: flex;
}
.Banner .BannerTitle .MuiContainer-root {
  align-self: center;
}
.Banner .BannerTitle img {
  width: auto;
  height: 100%;
  float: left;
  padding-right: 2.5rem;
  max-height: 7vh;
}
.Banner .banner__subtitle {
  color: rgba(255,255,255,.8);
}
.Banner .Button {
  margin: 1.5em 0;
  font-size: 1.75rem;
  text-transform: none;
  border: 2px solid white;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 700;
  line-height: 1em;
  padding: 0.78em 1.5em 0.78em;
}
.Banner .Button:hover {
  color: rgba(0,0,0,0.8);
  background: white;
  border-width: 2px;
}.Banner img
.Banner .msgold {
  margin: 0 1.5em 0 0;
  padding: 0.25em 0 0 0;
}

/* MAIN CONTENT */
.Main {
  padding: 3.6rem 0;
}
.Main .content .MuiGrid-root img {
  width: 5rem;
}
.Main .content img.centered {
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
}

/* SIDEBAR */
.sidebar {
  border-left: 1px solid rgba(34,36,38,.15);
  padding: 0 0 0 2.5rem;
  position: static;
}
.sidebar a:active,
.sidebar a:visited,
.sidebar a:link {
  color: rgba(0,0,0,0.87);
  width: 100%;
  line-height: 1.5;
}
.sidebar a:hover {
  color: rgba(30,64,92,.9);
}
.sidebar .MuiButton-root {
  padding: 0;
  justify-content: left;
  text-transform: inherit;
  font-size: inherit;
  color: rgba(0,0,0,0.87);
}
.sidebar .MuiPaper-root {
  padding: 0 !important;
  background: none !important;
  border: 0;
  box-shadow: none;
  margin-top: 8px !important;
}
.sidebar .MuiAccordionSummary-root {
  padding: 0;
}
.sidebar .MuiAccordionSummary-content {
  margin: 0;
}
.sidebar .MuiAccordionDetails-root {
  font-size: .85714286em !important;
  color: rgba(0,0,0,.5);
  padding: 0;
}

/* BLOG */
.Blog.Section img {
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
}
.Blog .Post .MuiChip-root {
  padding: 2rem 1rem;
  line-height: 1.2;
  margin: 0 0.5rem 0.5rem 0;
  position: relative;
  border-radius: 0.5rem;
  top: 0;
  left: 0;
}
.Blog .Post a:link.MuiChip-root,
.Blog .Post a:active.MuiChip-root,
.Blog .Post a:visited.MuiChip-root {
  color: rgba(255,255,255,0.87);
  background-color: rgba(0,168,228,1);
}
.Blog .Post a:hover.MuiChip-root {
  background-color: rgba(0, 116, 158, 0.9);
}
.Blog .MuiCardActions-root {
  padding: 0;
}
.Blog .MuiButton-root {
  text-transform: none;
  font-weight: bold;
  background-color: #e0e1e2;
  border: none;
  border-radius: 0 0 0.25em 0.25em;
  padding: 1rem;
}
.Blog .MuiButton-root:hover {
  background-color: #cacbcd;
  border: none;
}
.Blog a:link,
.Blog a:active,
.Blog a:visited {
  color: rgba(0,0,0,0.67);
}
.Blog .postfade {
  height: 5vw;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(rgba(255,255,255,0),rgba(255,255,255,1));
  left: 0;
  bottom: 0;
  z-index: 0;
}
.Blog .Breadcrumbs {
  margin-bottom: 1rem;
}

/* BOXES */
.Section {
  position: relative;
  padding: 5.6rem 0;
}
.Section .content {
  padding: 0 5.6rem 0 0;
}
.Section .sidebar {
  padding: 0 0 0 5.6rem;
}
.Section .closing-image {
  display: block;
  right: -5.6rem;
  bottom: -10%;
  width: 100%;
  position: relative;
  z-index: -10;
  max-width: 100vw;
  max-height: 100vh;
}
.Section.About.Main .content .MuiGrid-item .material-icons {
  font-size: 8.8rem !important;
}

/* INTRO */
.Intro {
  padding: 5.76rem 0;
}
.Intro .material-icons {
  font-size: 6rem !important;
  line-height: 1 !important;
  padding: 2rem;
  border: 0.5rem rgba(0,0,0,.1);
  box-shadow: inset 0 0 0 0.5rem rgba(0,0,0,.1);
}
.Intro .icon {
  color: grey;
  overflow: visible;
}

/* SUBFOOTER */
.SubFooter {
  padding: 5.76rem 0;
}

/* FORMS */
.ContactForm input {
 margin: auto;
}
.ContactForm .css-fjkvgf-MuiInputBase-root-MuiFilledInput-root {
  background-color: rgba(255,255,255,0.87);
}

.ContactForm .MuiFormControl-marginDense {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

/* FOOTER */
.Footer {
  padding: 0 0 5.76rem 0;
}
.Footer.decorated {
  padding: 0 0 5.76rem 0;
}
.Footer a:link, .Footer a:active, .Footer a:visited {
  color: #FFFFFF;
}
.Footer a:hover {
  color: #999999;
}
.Footer .contactinfo {
  font-size: 1.5rem;
}
.Footer .parentcompany {
  text-align: right;
}
.Footer .copyright {
  color: #999999;
}
.Footer .copyright .MuiTypography-root {
  font-size: 1.7rem;
}
.Footer .sociallinks {
  text-align: right;
}
.Footer .social {
  font-size: 2.5rem;
  display: flex;
  flex-direction: row;
  display: inline;
}
.Footer .social svg {
  width: 4.5rem;
  height: 4.5rem;
}

/* QHD to UHD CSS */
/* ############################### */
@media only screen and (min-width: 2300px) and (max-width: 3000px) {
  /* OVERRIDES */
  #root {
    font-size: 1.96rem;
  }
  /* CUSTOM CLASSES */
  .bullet .material-icons,
  .bullet svg {
    font-size: 6.5rem !important;
  }

  /* CUSTOM COMPONENTS */
  .Map {
    margin: 0 -5.6rem 0 -63%;
  }

  /* FOOTER */
  .Footer .copyright .MuiTypography-root {
    font-size: 1.5rem !important;
  }
}

/* DESKTOP to QHD CSS */
/* ############################### */
@media only screen and (min-width: 1935px) and (max-width: 2299px) {
  /* OVERRIDES */
  #root {
    font-size: 1.904rem;
  }
  /* CUSTOM CLASSES */
  .bullet .material-icons,
  .bullet svg {
    font-size: 6rem !important;
  }
  /* BANNER */
  .Banner .BannerTitle img {
    max-height: 6vh;
  }
  /* FOOTER */
  .Footer .copyright .MuiTypography-root {
    font-size: 1.3rem !important;
  }
}

/* LAPTOP to DESKTOP CSS */
/* ############################### */
@media only screen and (min-width: 1366px) and (max-width: 1934px) {
  /* OVERRIDES */
  #root {
    font-size: 1.6rem;
  }

  /* CUSTOM CLASSES */
  .bg-down {
    background-size: 50% 100% !important;
  }
  .bg-up {
    background-size: 50% 100% !important;
  }
  .bullet .material-icons,
  .bullet svg {
    font-size: 5.5rem !important;
  }

  /* NAVIGATIONS */
  .NavBar {
    top: 8vh !important;
  }
  /* TOP ALERTS */
  .Alert .right {
    font-size: 1.2rem;
    text-align: right;
  }
  .Alert .left {
    font-size: 1.2rem;
    text-align: left;
  }
  .Alert .material-icons {
    font-size: 1rem;
    padding: 0 0.5rem 0 0;
  }

  /* BANNER */
  .Banner .content {
    margin: 21vw auto;
  }
  .Banner .content img {
    height: 4.5vw;
  }
  .Banner .BannerTitle img {
    max-height: 6vh;
  }

  /* BOXES */
  .Section {
    padding: 5.6rem 0 !important;
  }

  /* INTRO */
  .Intro {
    padding: 3.25rem 0 2.5rem;
  }
  .Intro .material-icons {
    font-size: 3.75rem !important;
    padding: 2rem;
    border: 0.5rem rgba(0,0,0,.1);
    box-shadow: inset 0 0 0 0.5rem rgba(0,0,0,.1);
  }

  /* SUBFOOTER */
  .SubFooter {
    padding: 3.25rem 0 2.5rem;
  }

  /* FOOTER */
  .Footer .contactinfo {
    font-size: 1.25rem;
  }
  .Footer .copyright .MuiTypography-root {
    font-size: 1.4rem;
  }
  .Footer .social svg {
    width: 3.5rem;
    height: 3.5rem;
  }
}

/* TABLET to LAPTOP CSS */
/* ############################### */
@media only screen and (min-width: 744px) and (max-width: 1365px) {
  /* OVERRIDES */
  #root {
    font-size: 2.48rem;
  }

  /* CUSTOM CLASSES */
  .bullet .material-icons,
  .bullet svg {
    font-size: 5.5rem !important;
  }
  .OfficeSuite img {
    width: 30% !important;
  }
  .push__up {
    margin-bottom: -5.5em;
    top: -3.5em;
  }

  /* CUSTOM COMPONENTS */
  .Map {
    margin: 0 -27vw 0;
  }

  /* NAVIGATION */
  .NavBox {
    height: 8.25vw;
  }
  .NavMobile {
    background-color: #333 !important;
    color: white !important;
  }
  .NavMobile .MuiButtonBase-root {
    padding: 0.75em 0.75em 0.75em 1em;
  }
  .NavMobile .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
  }
  .NavMobile .MuiTypography-h1  {
    font-size: 2rem;
    margin-left: 0.5em;
  }
  .drawer .MuiPaper-root.MuiDrawer-paper {
    background: rgba(30,64,92,1);
    color: white;
  }
  .drawer .MuiButton-root {
    justify-content: flex-start;
    padding: 1.25rem;
    text-transform: none;
    width: 100%;
  }
  .drawer .MuiSvgIcon-root {
    font-size: 2.2rem !important;
    position: absolute;
    right: 0.75em;
    top: 0.75em;
  }
  .drawer .nav_9 {
    display: none;
  }
  .drawer .Menu {
    width: 100%;
    z-index: 100;
  }
  .drawer .Menu .MuiLink-root {
    width: 100%;
  }
  .drawer .Menu a:active,
  .drawer .Menu a:visited,
  .drawer .Menu a:link {
    color: rgba(0,0,0,0.87);
    padding: 1.25rem;
  }
  .MobileLogo {
    text-align: center;
    padding: 2.5rem;
  }
  .MobileLogo,
  .NavMobile,
  .NavMobile .mobile {
    display: block !important;
  }
  .NavBar,
  .NavBar .desktop,
  .NavFloat,
  .NavFloat .desktop,
  .NavMobile .desktop {
    display: none !important;
  }

  /* NAV LOGO */
  .MobileLogo {
    display: block;
    background-color: rgba(30,64,92,1);
    text-align: center;
  }
  .MobileLogo .logo {
    max-height: 8vw;
    width: auto;
  }

  /* TOP ALERTS */
  .Alert {
    padding: 1.5rem;
  }
  .Alert .right {
    font-size: 1.2rem;
    text-align: center;
  }
  .Alert .left {
    font-size: 1.2rem;
    text-align: center;
  }
  .Alert .material-icons {
    font-size: 1rem;
    padding: 0 0.5rem 0 0;
  }

  /* BANNER */
  .MobileLogo {
    padding: 5rem;
  }
  .MobileLogo img {
    max-width: 50vw !important;
    width: 100% !important;
  }
  .Banner.video {
    height: 125vw;
    max-height: 90vh;
    min-height: 45vh;
  }
  .Banner .BannerTitle {
    padding: 2.5rem 0;
    height: 4.7vh;
  }
  .Banner .BannerTitle img {
    max-height: 5vh;
  }
  .Banner.plain {
    height: 0;
  }

  /* MAIN CONTAINER */
  .Main .content img.centered {
    width: 100%;
    height: auto;
  }

  /* BOXES */
  .Section {
    padding: 7rem !important;
  }
  .Section .placeholder {
    min-height: 66.83vw;
  }
  .bg-down {
    background-position: center 100% !important;
    background-size: contain !important;
  }
  .bg-up {
    background-position: center top !important;
    background-size: contain !important;
  }
  .Section .closing-image {
    right: -0;
    bottom: -10vw;
    width: 100vw !important;
  }
  .Section .content {
    padding: 0;
  }
  .Section .sidebar {
    display: none;
  }
  .Section.Blog .sidebar {
    display: block;
    padding: 2.5rem;
    border-left: 0;
    margin-top: 2.5rem;
    background-color: #EAEAEA;
    border-radius: 1rem;
  }
  .Section.Blog .sidebar img {
    width: 50%;
  }

  /* INTRO */
  .Intro {
    padding: 3.5em 0 1.5em;
  }
  .Intro .material-icons {
    padding: 2.5rem;
    border: 1rem rgba(0,0,0,.1);
    box-shadow: inset 0 0 0 1rem rgba(0,0,0,.1)
  }

  /* SUBFOOTER */
  .SubFooter {
    padding: 7rem;
  }
  .SubFooter .clients img {
    height: 6.25vh;
    padding-bottom: 2em;
    width: auto;
  }
  .SubFooter .ClientSuccess img {
    max-width: 35vw;
    max-height: 15vh;
    width: 100%;
    height: auto;
  }

  /* FOOTER */
  .Footer img {
    max-width: 35vw;
    max-height: 15vh;
    width: 100%;
    height: auto;
  }
  .Footer .contactinfo {
    text-align: center;
    padding: 5.6rem 0 0;
  }
  .Footer .parentcompany {
    text-align: center;
    padding: 5.6rem 0 0;
  }
  .Footer .copyright {
    text-align: center;
    padding: 2.5rem 0 2.5rem 0;
  }
  .Footer.decorated {
    padding: 0;
  }
  .Footer .copyright .MuiTypography-root {
    font-size: 1.4rem;
  }
  .Footer .sociallinks {
    text-align: center;
    padding: 0 0 3.5rem 0;
  }
  .Footer .social svg {
    width: 3.5rem;
    height: 3.5rem;
  }
}

/* MOBILE to TABLET CSS */
/* ############################### */
@media only screen and (min-width: 0px) and (max-width: 743px) {
  /* OVERRIDES */
  #root {
    font-size: 1.6rem;
  }

  /* CUSTOM CLASSES */
  .bullet .material-icons,
  .bullet svg {
    font-size: 3rem !important;
  }
  .OfficeSuite img {
    width: 30% !important;
  }

  /* CUSTOM COMPONENTS */
  .Map {
    margin: 0 -20vw 0;
  }
  .push__up {
    margin-bottom: -9.5rem;
    top: -6.5rem;
  }

  /* NAVIGATION */
  .NavBox {
    height: 12.5vw;
  }
  .NavMobile {
    background-color: #333 !important;
    color: white !important;
  }
  .NavMobile .MuiButtonBase-root {
    padding: 0.75em 0.75em 0.75em 1em;
  }
  .NavMobile .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
  }
  .NavMobile .MuiTypography-h1  {
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
  .drawer .MuiPaper-root.MuiDrawer-paper {
    background: rgba(30,64,92,1);
    color: white;
  }
  .drawer .MuiButton-root {
    justify-content: flex-start;
    padding: 1.25rem;
    text-transform: none;
    width: 100%;
  }
  .drawer .MuiSvgIcon-root {
    font-size: 1.66rem !important;
    position: absolute;
    right: 0.75em;
    top: 0.75em;
  }
  .drawer .nav_9 {
    display: none;
  }
  .drawer .Menu {
    width: 100%;
    z-index: 100;
  }
  .drawer .Menu .MuiLink-root {
    width: 100%;
  }
  .drawer .Menu a:active,
  .drawer .Menu a:visited,
  .drawer .Menu a:link {
    color: rgba(0,0,0,0.87);
    padding: 1rem;
  }
  .MobileLogo {
    text-align: center;
    padding: 2.5rem;
  }
  .MobileLogo,
  .NavMobile,
  .NavMobile .mobile {
    display: block !important;
  }
  .NavBar,
  .NavBar .desktop,
  .NavFloat,
  .NavFloat .desktop,
  .NavMobile .desktop {
    display: none !important;
  }

  /* NAV LOGO */
  .MobileLogo {
    display: block;
    background-color: rgba(30,64,92,1);
    text-align: center;
  }
  .MobileLogo .logo {
    max-height: 15vw;
    width: auto;
  }

  /* TOP ALERT */
  .Alert {
    text-align: center;
  }
  .Alert .right {
    font-size: 1rem;
    text-align: center;
    padding-top: 0.5em;
  }
  .Alert .left {
    font-size: 1rem;
    text-align: center;
    padding-top: 0.5em;
  }
  .Alert .material-icons {
    font-size: 1rem;
    padding: 0 0.5rem 0 0;
  }

  /* BANNER */
  .MobileLogo img {
    max-width: 50vw !important;
    width: 100% !important;
  }
  .Banner.plain {
    display: none;
  }
  .Banner.video {
    height: calc(100vw - 4vh);
    max-height: 95vh;
    min-height: 45vh;
  }
  .Banner .Button {
    margin: 1rem;
    font-size: 1rem;
  }
  .Banner .BannerTitle {
    padding: 1rem 0;
    height: 9.4vh;
  }
  .Banner .BannerTitle h6.MuiTypography-subtitle2 {
    display: none;
  }

  /* BOXES */
  .Section {
    padding: 3.5rem !important;
  }
  .Section .placeholder {
    min-height: 66.83vw;
  }
  .bg-down {
    background-position: center 100% !important;
    background-size: contain !important;
  }
  .bg-up {
    background-position: center top !important;
    background-size: contain !important;
  }
  .Section .closing-image {
    right: 0;
    bottom: -9vw;
    width: 100vw !important;
  }
  .Section .content {
    padding: 0;
  }
  .Section .sidebar {
    display: none;
  }
  
  /* INTRO */
  .Intro {
    padding: 3.5em 0 1.5em;
  }
  .Intro .material-icons {
    font-size: 3rem !important; 
    padding: 1.25rem;
    border: 0.5rem rgba(0,0,0,.1);
    box-shadow: inset 0 0 0 0.5rem rgba(0,0,0,.1)
  }

  /* SUBFOOTER */
  .SubFooter {
    padding: 3.5rem;
  }
  .SubFooter .clients img {
    height: 6.25vh;
    padding-bottom: 2em;
    width: auto;
  }
  .SubFooter .ClientSuccess img {
    max-width: 45vw;
    max-height: 15vh;
    width: 100%;
    height: auto;
  }

  /* FOOTER */
  .Footer img {
    max-width: 55vw;
    width: 100%;
    height: auto;
  }
  .Footer .contactinfo {
    font-size: 1.2rem;
    text-align: center;
    padding: 3.5rem 0 0;
  }
  .Footer .contactinfo .material-icons {
    padding: 0 0.5rem 0 0;
  }
  .Footer .parentcompany {
    text-align: center;
    padding: 2.5rem 0 0;
  }
  .Footer .copyright {
    text-align: center;
    padding: 2.5rem 0 2.5rem 0;
  }
  .Footer .copyright .MuiTypography-root {
    font-size: 1.22rem;
  }
  .Footer .sociallinks {
    text-align: center;
    padding: 0 0 3.5rem 0;
  }
  .Footer .social svg {
    width: 3.5rem;
    height: 3.5rem;
  }
}